/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300,700,800|Hind:300,400,500,600,700');
@import "sass-lib";

body {  
    color: #333;
    font-size: 14px;
    font-family: 'Hind', sans-serif;
    position: relative;
    overflow-x: hidden;
}
figure{
    margin: 0;
    img{vertical-align: top;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    font-size: 16px; line-height: 24px;
}
hr{
    border: none; height: 1px; background: #e6e6e6; margin: 10px 0; display: block;
    &.linegreen{background: $green;height: 2px;margin: 10px 0 40px;}
}
input, textarea, select {
    font-size: 14px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: 'Hind', sans-serif;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
h1 { font-size: 45px; font-weight: 700; text-transform: uppercase;}
h2 { font-size: 36px; font-weight: 700; text-transform: uppercase;}
h3 { font-size: 30px; font-weight: 700; text-transform: uppercase; margin-bottom: 10px;}
h4 { font-size: 25px; font-weight: 700; text-transform: uppercase;} 
h5 { font-size: 18px; font-weight: 700;} 
h6 { font-size: 16px; font-weight: 700; color: $blue; text-transform: uppercase; line-height: 24px; margin-bottom: 5px;}
/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper-lg {width: 1600px;margin: 0 auto; max-width: 100%;}
.wrapper {width: 1170px;margin: 0 auto; max-width: 100%;}

/* global
----------------------------------------------------------------------------------------------*/
.italic { font-style: italic; }
.bold { font-weight: bold; }
.regular { font-weight: lighter; }
.fl { float: left; }
.fr { float: right; }
.clear { clear: both; }
.after_clear:after,.wrapper:after, .wrapper-lg:after,section:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}
.a-left{text-align: left}
.a-right{text-align: right}
.a-center{text-align: center}
.bggradient{
    background: rgba(2,55,112,1);
    background: -moz-linear-gradient(left, rgba(2,55,112,1) 0%, rgba(0,120,88,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(2,55,112,1)), color-stop(100%, rgba(0,120,88,1)));
    background: -webkit-linear-gradient(left, rgba(2,55,112,1) 0%, rgba(0,120,88,1) 100%);
    background: -o-linear-gradient(left, rgba(2,55,112,1) 0%, rgba(0,120,88,1) 100%);
    background: -ms-linear-gradient(left, rgba(2,55,112,1) 0%, rgba(0,120,88,1) 100%);
    background: linear-gradient(to right, rgba(2,55,112,1) 0%, rgba(0,120,88,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#023770', endColorstr='#007858', GradientType=1 );
}
.bggradient2{
    background: rgba(0,120,88,1);
    background: -moz-linear-gradient(left, rgba(0,120,88,1) 0%, rgba(2,55,112,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,120,88,1)), color-stop(100%, rgba(2,55,112,1)));
    background: -webkit-linear-gradient(left, rgba(0,120,88,1) 0%, rgba(2,55,112,1) 100%);
    background: -o-linear-gradient(left, rgba(0,120,88,1) 0%, rgba(2,55,112,1) 100%);
    background: -ms-linear-gradient(left, rgba(0,120,88,1) 0%, rgba(2,55,112,1) 100%);
    background: linear-gradient(to right, rgba(0,120,88,1) 0%, rgba(2,55,112,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007858', endColorstr='#023770', GradientType=1 );
}
.line-yl{width: 30px; height: 6px; background: $yellow; display: inline-block;}
.hline{
    position: relative;
    .line-yl{position: absolute; top: 7px; left: -60px;}
}
.ln-fix{display: block; margin-bottom: 30px;}
.bg-blue{background: $blue;}
.bg-green{background: $green;}
.link-red{
    font-weight: 700; font-size: 14px; line-height: 24px; color: $pink;
    .icwp{margin-left: 5px; vertical-align: middle; position: relative; left: 0; @include transition(0.2s all);}
    &:hover{
        .icwp{left: 5px;}
    }
}
.link-bold{font-weight: 700; color: #333333; font-size: 16px; line-height: 24px;}
.htelp{margin-bottom: 10px;}

.btn-oval{
    display: inline-block; background: $pink; @include borderRadius(30px); color: $white; font-size: 14px; font-weight: bold; border: none; height: 50px; line-height: 50px; padding-left: 30px; padding-right: 30px; cursor: pointer; @include transition(0.2s all); @include boxSizing(border-box);

    &.btn-grablue{
        @extend .bggradient;
        &:hover{color: $yellow;}
    }
    &.btn-pink{
        background: $pink;
        .icwp{@include transition(0.2s all);}
        &:hover{
            color: $pink; background: $white;
            .icwp{background-position: bottom center;}
        }
    }
    &.btn-ln-pink{
        background: transparent; border: 2px solid $pink; color: $pink; height: 30px; line-height: 28px; padding-left: 15px; padding-right: 15px;
        .ic_link_pink{vertical-align: middle; margin-left: 5px; position: relative; left: 0; @include transition(0.2s left); top: -1px;}
        &:hover{
            color: $pink;
            .ic_link_pink{left: 5px;}
        }
    }
    &.btn-ln-grey{
        background: transparent; border: 2px solid #999999; color: #999999; height: 30px; line-height: 28px; padding-left: 10px; padding-right: 10px;
        .ic_link_back, .ic_link_next{vertical-align: middle; position: relative; @include transition(0.2s left);}
        .ic_link_back{margin-right: 5px; position: relative; left: 0;}
        .ic_link_next{margin-left: 5px; position: relative; right: 0;}
        &:hover{
            .ic_link_back{left: -3px;}
            .ic_link_next{right: -3px;}
        }
    }
    &.btn-purple{
        background: $purple;
        .icwp{@include transition(0.2s all);}
        &:hover{
            background: $white; color: $purple;
            .icwp{background-position: bottom center;}
        }
    }
    &.btn-submit{
        line-height: 50px; padding-top: 0; padding-bottom: 0;
    }
    &.btn-submit-big{
        line-height: 0; height: 60px; padding: 0 90px; font-size: 20px; text-transform: uppercase; font-weight: 700;
    }
    &.btn-thankspage{
        font-size: 16px;height: 50px; line-height: 50px;
        .ic_link_back_yl{vertical-align: middle; margin-right: 5px;position: relative; left: 0; @include transition(0.2s left); top: -1px;}
        &:hover{
            .ic_link_back_yl{left: -5px;}
        }
    }

    .ic_proteksi, .ic_simulasi{margin-right: 5px;}
    .ic_link_white{position: relative; left: 0; @include transition(0.2s all); margin-left: 5px;}
    &:hover{
        color: $yellow;
        .ic_link_white{left: 5px;}
    }
}



/* icons icwp 
-----------------------------------------*/
.icwp{
    width: 19px; height: 19px; background-repeat: no-repeat; background-position: top center; display: inline-block; position: relative;
    &:hover{background-position: bottom center;}

    &.ic_link{background-color: $yellow; background-image: url('../images/material/ic_arrow_link.png'); @include borderRadius(100%); width: 20px; height: 20px; background-position: center;
        &:hover{background-position: center;}
    }
    &.ic_link_back_yl{background-color: $yellow; background-image: url('../images/material/ic_link_back_yl.png'); @include borderRadius(100%); width: 20px; height: 20px; background-position: center;
        &:hover{background-position: center;}
    }
    &.ic_link_pink{
        background-color: $pink; background-image: url('../images/material/ic_arrow_link.png'); @include borderRadius(100%); width: 13px; height: 13px; background-position: center;
    }
    &.ic_link_back{
        background-color: #999; background-image: url('../images/material/ic_link_back.png'); @include borderRadius(100%); width: 13px; height: 13px; background-position: center;
    }
    &.ic_link_next{
        background-color: #999; background-image: url('../images/material/ic_link_next.png'); @include borderRadius(100%); width: 13px; height: 13px; background-position: center;
    }
    &.ic_link_white{
        background-color: $white; background-image: url('../images/material/ic_link_back_red.png'); @include borderRadius(100%); width: 12px; height: 12px; background-position: center; vertical-align: middle;
    }
    &.ic_mouse{background-image: url('../images/material/ic_mouse.png'); width: 17px; height: 26px;}
    &.ic_proteksi{background-image: url('../images/material/ic_proteksi.png'); width: 23px; height: 27px; vertical-align: middle;}
    &.ic_simulasi{background-image: url('../images/material/ic_simulasi.png'); width: 23px; height: 27px; vertical-align: middle;}
    &.ic_download{background-color: $pink; background-image: url('../images/material/ic_download.png'); @include borderRadius(100%); width: 12px; height: 12px; background-position: center;
        &:hover{background-position: center;}
    }
    &.ic_gettouch{background-color: $pink; background-image: url('../images/material/ic_gettouch.png'); @include borderRadius(100%); width: 12px; height: 12px; background-position: center;
        &:hover{background-position: center;}
    }
    &.ic_plus{background-image: url('../images/material/ic_plus.png'); width: 10px; height: 10px;}
    &.ic_drag_side{background-image: url('../images/material/ic_drag_side.png'); width: 36px; height: 5px;}
    &.ic_download_grey{background-image: url('../images/material/ic_download_grey.png'); width: 10px; height: 15px; vertical-align: middle;}
    &.ic_arr_bottom{background-image: url('../images/material/ic_arr_bottom.png'); width: 9px; height: 6px; vertical-align: middle;}
    &.ic_upload_foto{background-image: url('../images/material/ic_upload_foto.png'); width: 30px; height: 28px; vertical-align: middle;}
    &.ic_upload_file{background-image: url('../images/material/ic_upload_file.png'); width: 30px; height: 28px; vertical-align: middle;}
    &.ic_new_file{background-image: url('../images/material/ic_new_file.png'); width: 30px; height: 28px; vertical-align: middle;}

    &.ic_fb, &.ic_tw, &.ic_yt, &.ic_wa, &.ic_ln, &.ic_ig{width: 18px; height: 18px;}
    &.ic_fb{background-image: url('../images/material/ic_fb.png');}
    &.ic_tw{background-image: url('../images/material/ic_tw.png');}
    &.ic_yt{background-image: url('../images/material/ic_yt.png');}
    &.ic_wa{background-image: url('../images/material/ic_wa.png');}
    &.ic_ln{background-image: url('../images/material/ic_line.png');}
    &.ic_ig{background-image: url('../images/material/ic_ig.png');}

    &.ic_g_phone, &.ic_g_fax, &.ic_g_mail, &.ic_g_world{width: 15px; height: 21px; vertical-align: middle;}
    &.ic_g_phone{background-image: url('../images/material/ic_g_phone.png');}
    &.ic_g_fax{background-image: url('../images/material/ic_g_fax.png');}
    &.ic_g_mail{background-image: url('../images/material/ic_g_mail.png');}
    &.ic_g_world{background-image: url('../images/material/ic_g_world.png');}

    &.ic_proteksi2, &.ic_layanan, &.ic_berita, &.ic_about, &.ic_karir, &.ic_kontak{width: 26px; height: 21px; vertical-align: middle;}
    &.ic_proteksi2{background-image: url('../images/material/ic_proteksi_01.png');}
    &.ic_layanan{background-image: url('../images/material/ic_layanan.png');}
    &.ic_berita{background-image: url('../images/material/ic_berita.png');}
    &.ic_about{background-image: url('../images/material/ic_about.png');}
    &.ic_karir{background-image: url('../images/material/ic_karir.png');}
    &.ic_kontak{background-image: url('../images/material/ic_kontak.png');}
    &.ic_home{background-image: url('../images/material/ic_home.png');width: 15px;height: 14px;}
}
/* end icons icwp 
-----------------------------------------*/


/* row list styles 
-----------------------------------------*/
.row_list{
    margin-left: -15px; margin-right: -15px; @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    &:after{content: ""; display: table; clear: both;}
    .col{
        width: 100%; padding: 0 15px; float: left;
        &.fr{float: right;}
        &.no-pad{padding: 0;}
        &.marg28{margin-bottom: 28px;}
        &.col_7{width: 70%;}
        &.col_66{width: 66.66666666666666%;}
        &.col_65{width: 65%;}
        &.col_6{width: 60%;}
        &.col_55{width: 55%;}
        &.col_5{width: 50%;}
        &.col_45{width: 45%;}
        &.col_4{width: 40%;}
        &.col_37{width: 37%;}
        &.col_35{width: 35%;}
        &.col_33{width: 33.3333333333333%;}
        &.col_3{width: 30%;}
        &.col_25{width: 25%;}
        &.col_2{width: 20%;}
        &.col_16{width: 16.66666666666666%;}
        &.col_1{width: 10%;}
    }
    &.center{
        text-align: center;
        .col{float: none; display: inline-block;}
    }
    &.listfull{
        margin-left: 0; margin-right: 0;
        .col{padding: 0;}
    }
    &.listp5{
        margin-left: -5px; margin-right: -5px;
        .col{padding: 0 5px;}
    }
}
.col{
    p{
        color: #000;
    }
}
/* end row list styles 
-----------------------------------------*/


/* header
----------------------------------------------------------------------------------------------*/
header{
    display: block; background: $white; z-index: 100; border-bottom: 1px solid #007758; position: absolute; top: 0; left: 0; right: 0; height: 108px;
    .logo{
        float: left; padding: 15px 0;
        img{@include transition(0.2s width); width: 200px;}
    }
    .nav_header{
        float: right; margin-top: 70px; width: 1190px; @include transition(0.2s margin);
        .nav_left{
            float: left; padding-top: 5px;
            li{
                float: left; margin: 0 30px;
                a{
                    font-size: 14px; line-height: 20px; text-transform: uppercase; color: $blue; font-weight: 700;
                    &:hover, &.active{
                        color: $green; 
                        .icwp{background-position: bottom center;}
                    }
                }
                &.active{
                    a{color: $green;}
                    .icwp{background-position: bottom center;}
                }
            }
            .bg-close{display: none;}
        }
        .nav_right{
            float: right; padding-top: 2px;
            .box-search{
                &:after{content: ""; width: 1px; height: 24px; background: #eaeaea; position: absolute; top: 0; right: 0;}
            }
        }
        .nav_mobile{display: none;}
    }
    .burg-menu{display: none;}
    &.float{
        height: 78px;
        .logo{
            padding: 10px 0;
            img{width: 150px;}
        }
        .nav_header{margin-top: 40px;}
        .btn-buy {margin-top: -10px;}
    }
    .btn-buy{float: left; height: 35px; line-height: 35px; padding-left: 20px; padding-right: 20px; margin-left: 20px; margin-top: -5px;}
}
.box-search-nav{display: none;}
.loader{position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: $white url('../images/material/logo-loader.png') no-repeat center; z-index: 10000;}
.cart-link{
    width: 25px; height: 25px; background: url('../images/material/ic_cart.png') no-repeat top center; display: block; float: left; margin-right: 20px; position: relative; background-size: 100%;
    &:after{content: "";width: 1px;height: 24px;background: #eaeaea;position: absolute;top: 0;right: -15px;}
    &:hover{background-position: bottom center;}
}
.box-search{
    position: relative; float: left; padding: 0 15px 0 0;
    input[type=text]{border: none; background: transparent; color: #999999; width: 115px; float: left; padding: 0 10px; margin-right: 0; font-weight: 500;}
    .btn-search{border: none; background: transparent url('../images/material/ic_search.png') no-repeat center; width: 17px; height: 17px;}
    .btn-search-mb{display: none;border: none; background: transparent url('../images/material/ic_search.png') no-repeat center; width: 17px; height: 17px; padding: 0;}
}
.box-lang{
    position: relative; float: left; padding: 5px 0 0 15px;
    a{
        font-weight: 700; color: $blue;
        &:hover, &.active{color: $yellow;}
    }
    .tmain{
        color: $yellow; position: relative; padding-right: 15px;
        &:after{content: ""; position: absolute; top: 50%; right: 0; border-top: 4px solid $blue;border-left: 4px solid transparent;border-right: 4px solid transparent; @include transform(translateY(-50%));}
    }
    ul{
        position: absolute; top: 100%; left: 0; z-index: 1; background: $white; display: none;
        li{
            padding: 5px 15px;
            &:first-child{padding-top: 15px;}
        }
    }
}


/* middle
----------------------------------------------------------------------------------------------*/
.slidehome{
    .list-slide{
        position: relative; height: 100vh; min-height: 650px; @include boxSizing(border-box); padding-top: 109px;
        figure{
            overflow: hidden; height: 100%; position: relative;
            img{min-height: 100%; min-width: 100%; max-width: none; @include transform(translate(-50%, -50%)); position: absolute; top: 50%; left: 50%; width: 100vw;height: 94vh;object-fit: cover;}
        }
        .desc{
            position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%)); text-align: center; width: 970px; max-width: 100%; text-transform: uppercase; z-index: 10; padding-top: 50px;
            .line-yl{width: 54px; height: 10px; clear: both; margin-bottom: 20px;}
            h5{color: $white; margin-bottom: 10px;}
            h1{color: $white; margin-bottom: 65px; font-size: 65px; line-height: 55px;}
            .ic_link{vertical-align: middle; margin-left: 5px; position: relative; @include transition(0.2s left); left: 0;}
            .btn-grablue{
                &:hover{
                    .ic_link{left: 5px;}
                }
            }
        }
    }
    .bg-overlay{position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.3); z-index: 1;}
    .bg-shadow-bottom{position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: url('../images/material/shadow-slide-home.png') no-repeat bottom center;}
    .sc-bottom{
        position: absolute; bottom: 0; left: 0; right: 0; text-align: center; width: 87px; margin: 0 auto; font-size: 12px; line-height: 12px; padding-bottom: 8px; cursor: pointer;
        &:after{content: ""; position: absolute; bottom: 22px; left: 0; right: 0; width: 1px; background: $white; margin: 0 auto; height: 0; @include transition(0.2s all);}
        span{display: block; text-align: center; color: $white; margin-bottom: 10px; @include transition(0.2s color);}
        &:hover{
            &:after{height: 20px; bottom: 2px;}
            span{color: $yellow;}
        }
    }
    .slick-prev, .slick-next{
        width: 25px; height: 60px; background-image: url('../images/material/arrow_slidenews.png'); background-repeat: no-repeat; background-size: 200% 100%; z-index: 10; margin-top: 55px;
        &:before{display: none;}
    }
    .slick-prev{left: 50px; background-position: left center;}
    .slick-next{right: 50px; background-position: right center;}
}

.sec-proteksi{position: relative;}
.trigger-anim-banner{position: absolute; top: 40px;}
.trigger-anim-proteksi{position: absolute; top: 50%;}
.box-banner-registration{
    @extend .bggradient2; color: $white; padding: 10px 0; z-index: 11;
    .left{padding-top: 15px;}
    .right{text-align: right;}
    .btn-pink{font-size: 20px; max-width: 100%;}
}
.box-proteksi{
    position: relative; @include fullfloat; padding: 20px 0; @include boxSizing(border-box); overflow: hidden;
    *{@include boxSizing(border-box);}
    .img-proteksi{
        width: 46.35%; position: relative;
        img{position: absolute; top: 50%;@include transform(translateY(-50%));}
    }
    .desc-proteksi{
        width: 53.64%; display: table; height: 500px;
        p{font-size: 16px; line-height: 24px;}
        h3{
            &.hline{
                .line-yl{position: absolute; top: 7px; left: -60px;}
            }
        }
        p{margin-bottom: 30px;}
        .inner-proteksi{display: table-cell; vertical-align: middle;}
    }
    .bg-proteksi{width: 69.66%; height: 500px; display: block;}

    .center{
        position: absolute; top: 0; bottom: 0; left: 50%; @include transform(translateX(-50%)); z-index: 10;
        &:before{content: ""; width: 1px; background: #eaeaea; position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: 0 auto;}
        .oval{padding: 12px 30px; color: $white; background: $yellow; @include borderRadius(30px); font-weight: 700; font-size: 16px; text-transform: uppercase;
            position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%) rotate(-90deg)); @include boxSizing(border-box); width: 240px; text-align: center; @include transformOrigin(50% 50%);
        }
    }
    .left, .right{width: 50%;}
    .left{float: left;
        .img-proteksi{
            float: left;
            img{left: -20px; right: auto;}
        }
        .desc-proteksi{float: right; padding-right: 85px;}
        .bg-proteksi{float: left;}
    }
    .right{
        float: right;
        .img-proteksi{
            float: right;overflow: hidden;
            img{right: -20px; left: auto;}
        }
        .desc-proteksi{
            float: left;padding-left: 80px; text-align: right;
            h3{
                &.hline{
                    .line-yl{position: absolute; top: 7px; left: auto; right: -60px;;}
                }
            }
        }
        .bg-proteksi{float: right;}
    }
}

.sec-about{
    background: #d9e3ec; position: relative; overflow: hidden;
    .box-inner-sec-about{
        &:after{content: " ";clear: both; display: block;}
    }
    .left{width: 58.07%;
        figure{
            overflow: hidden;
            img{vertical-align: bottom;}
        }
    }
    .right{
        width: 41.92%; position: absolute; top: 50%; right: 0; @include transform(translateY(-50%));
        h1{margin-bottom: 20px;}
        p{margin-bottom: 45px;}
        .inner-sec-about{width: 460px; max-width: 100%;}
    }

    .trigger-anim2{position: absolute; top: 40%}
}

.sec-solution{
    background: #f7f7f7; position: relative; overflow: hidden;
}
.trigger-anim-solution{position: absolute; top: 100px;}
.trigger-anim-news{position: absolute; top: 70%;}
.sec-news-list{
    padding: 60px 0;
}
.box-banner-solution{
    @extend .bggradient; color: $white; padding: 65px 0;
    .left{
        width: 47%;
        p{font-size: 20px; line-height: 24px; margin-bottom: 0;
            span{font-weight: 700; color: $yellow;}
        }
    }
    .right{
        width: 50%; text-align: right;
        .btn-oval{
            margin-left: 15px;
            &:first-child{margin-left: 0;}
        }
    }
}
.box-news-home{
    padding: 80px 0 55px;
    .box-title-news{
        width: 13.43%; float: left; color: $green;
        h1{margin-bottom: 25px; line-height: 40px;}
    }
    .box-news-list-home{
        width: 82.5%; float: right;
    }
}
.box-news-list-home{
    @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    .list{
        width: 31%; margin-right: 3%; float: left; position: relative;
        &:last-child{margin-right: 0;};
        h4{text-transform: none; @include transition(0.2s color);}
        a{
            &:hover{
                color:$yellow;
                h4{color:$yellow;}
            }
        }
        .date{
            position: absolute; top: 0; left: 0; width: 70px; padding: 13px 10px; color: $white; background: $green; text-align: center;
            h3{margin-bottom: 0;}
        }
        .desc{
            padding-left: 80px; color: #666666;
            h4{font-weight: 500;line-height: 28px; color: #111; margin-bottom: 10px;}
            p{font-size: 14px; line-height: 20px;}
        }
    }
}

.box-title{
    @include boxSizing(border-box); padding-left: 332px; position: relative; margin: 88px 0 84px;
    .line-vly{position: absolute; bottom: 0; left: 290px; width: 10px; height: 294px; background: $yellow;}
    h2{font-size: 40px; line-height: 55px; text-transform: none; font-weight: 300;}
    h4{font-size: 20px; text-transform: uppercase; font-weight: 300;}
    h6{margin-bottom: 25px;}
    .date-title{font-size: 13px; color: #979696; margin-bottom: 32px; display: block;}
    &.nopadd{
        padding-left: 42px;
        .line-vly{left: 0;}
    }
    h5{font-size: 17px; font-weight: 300; line-height: 25px; color: #333;}
}

.banner{
    margin-top: 108px; @include fullinline; overflow: hidden;
    &:after{content: " "; clear: both; display: block;}
}

.box-btn-tab{
    text-align: center; @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    .title-btn-tab{display: none;}
    .inner-btn-tab{
        @include borderRadius(50px); display: inline-block; position: relative; width: 100%; text-align: center; position: relative;
        .bgdragg{position: absolute; top: 0; left: 0; bottom: 0; background: $blue; width: 20%; @include borderRadius(50px); z-index: -1; @include transition(0.4s all);}
    }
    .list{
        display: inline-block; height: 50px; width: 20%; background: transparent; text-align: center; line-height: 50px; color: #666666; @include transition(0.2s all);
        font-size: 16px;text-transform: uppercase; float: left; padding: 0 40px; cursor: pointer; border-top: 1px solid #c2c2c2; border-bottom: 1px solid #c2c2c2;
        /*&:first-child{margin-left: 0;}*/
        &:hover{font-weight: 700;}
        &:nth-child(2){border-left: 1px solid #c2c2c2; @include borderRadius(50px 0 0 50px);}
        &:last-child{border-right: 1px solid #c2c2c2; @include borderRadius(0 50px 50px 0);}
        &.active{color: $white; font-weight: 700;}
    }
    &.blleft{
        text-align: left;
    }
    &.board-btn{
        .inner-btn-tab{
            .bgdragg{width: 30%;}
        }
        .list{padding: 0 20px; width: 30%;}
    }
}

.box-content-tab{
    .list-tab{
        display: none;
        /*&.active{display: block;}*/
    }
}

.box-product{
    margin-top: 75px; margin-bottom: 120px;
    .list-product{
        margin-bottom: 93px; display: block;
        &:after{clear: both;display: block;content:" "}
        h3{margin-bottom: 38px;}
        p{margin-bottom: 65px;}
        .img-prod{float: left; width: 59.82%;}
        .desc-prod{float: right; width: 31.62%;}

        &:nth-child(2n){
            .img-prod{float: right;}
            .desc-prod{float: left;}
        }
        &:last-child{margin-bottom: 0;}
    }
}

.box-content{
    font-size: 16px; line-height: 24px; margin-bottom: 38px;
    figure{margin-bottom: 50px;}
    h4{font-size: 24px; line-height: 24px; color: $blue; margin-bottom: 35px;}
    h5{color: $green; line-height: 24px; text-transform: uppercase; margin-bottom: 20px;}
    strong{font-weight: 700; color: #666666;}
    ol, ul{
        margin-bottom: 15px;
        li{
            padding-left: 35px; position: relative; font-size: 16px; line-height: 24px;
        }
    }
    ul{
        li{
            &:before{content: ""; position: absolute; top: 6px; left: 10px; background: $yellow; width: 8px; height: 8px;}
            ol {
                li {
                    &:before {background: none;}
                    ol {
                        > li {
                            counter-increment: step1;
                            &:before {
                                content:counter(step1)"."; position: absolute; left: 10px; top: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    ol {
        > li {
            counter-increment: step;
            &:before {
                content:counter(step)"."; position: absolute; left: 10px; top: 0px;
            }
            ol {
                > li {
                    counter-increment: step2;
                    &:before {
                        content:counter(step2)"."; position: absolute; left: 10px; top: 0px;
                    }
                    ol {
                        > li {
                            counter-increment: step3;
                            &:before {
                                content:counter(step3)"."; position: absolute; left: 10px; top: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    iframe{width: 100%; height: 500px;}
    .wrap_btn2{
        height: 60px;
        h4{
            float: left; 
        }
        a{
            float:right; left: 0; right: 0; margin-top: -15px;
        }
        &:after{content: " "; clear: both; display: block;}
    }
    .sharethis-inline-share-buttons{margin-bottom: 15px;}
}

.box-two-list{
    @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    .list{
        width: 50%; float: left; position: relative; margin-bottom: 30px;
        .left{width: 51.28%;}
        .right{
            width: 39.42%; padding-right: 20px; position: absolute; top: 50%; @include transform(translateY(-50%)); right: 0;
        }
    }
    &:after{content: " "; clear: both; display: block;}
    &.boxtwomarg{margin-bottom: 120px;}
}
.box-three-list{
    @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    .list{
        width: 33.3333333333%; float: left;
    }
}
.box-four-list{
    @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    .list{
        width: 25%; float: left;
        &:nth-child(4n + 1){clear: both;}
    }
}

aside{
    width: 23.07%; float: left; padding-top: 88px;
    ul{
        li{
            margin-bottom: 25px;
            a{
                font-size: 18px; color: #666666; line-height: 24px;
                &.active{font-weight: 700; color: $green;}
                &:hover{color: $green;}
            }
        }
    }
}
article{
    width: 74.44%; float: right; @include boxSizing(border-box); padding-left: 33px; padding-top: 88px; padding-bottom: 50px;
    .box-title{
        padding-left: 0; margin-top: 0; margin-bottom: 40px;
        .line-vly{left: -44px;}
    }
}

.box-link-reward{
    @include boxSizing(border-box); border: 1px solid #eaeaea;
    *{@include boxSizing(border-box);}
    .list{
        border-bottom: 1px solid #eaeaea;padding: 45px 170px 45px 82px; position: relative;
        span{display: block; font-size: 16px; margin-bottom: 5px;}
        h5{font-size: 20px; color: #333; float: left; margin-bottom: 0;}
        a{
            position: absolute; top: 50%; right: 50px; color: #999999; font-size: 14px; @include transition(0.2s all); @include transform(translateY(-50%)); font-weight: 700; text-transform: uppercase;
            .icwp{margin-right: 10px;}
            &:hover{color: $yellow;}
        }
        .line-yl{width: 15px; @include transition(0.2s all);}
        &:before{content: ""; @extend .bggradient; position: absolute; top: 0; left: 0; bottom: 0; right: 0; opacity: 0;@include transition(0.2s all); z-index: -1;}
        &:after{content: " "; clear: both; display: block;}
        &:last-child{border-bottom: none;}
        &:hover, &.active{
            color: $white;
            &:before{opacity: 1;}
            h5, span{color: $white;}
            a{
                color: $white;
                .icwp{background-position: bottom center;}
                &:hover{color: $yellow;}
            }
            .line-yl{width: 30px;}
        }
    }
}

.box-board{
    margin-top: 46px;
    .list-board{
        margin-bottom: 80px;
        &:after{content: " ";clear: both; display: block;}
        h6{margin-bottom: 15px;}
        h3{color: #666666; margin-bottom: 26px; line-height: 28px;}
        p{margin-bottom: 30px;}
        .img-board{width: 47.55%; float: left;}
        .desc-board{width: 40.13%; float: right; padding-top: 40px;}
        &:nth-child(2n){
            text-align: right;
            .img-board{float: right;}
            .desc-board{float: left;}
            .hline{
                .line-yl{left: auto; right: -60px;}
            }
        }
    }
    &.box-board-detail{
        .img-board{width: 47.67%;}
        .desc-board{
            width: 47.67%; padding-top: 20px;
            p{margin-bottom: 15px;}
            .line-yl{margin-bottom: 20px;}
        }
    }
}

.box-drag-nav{
    position: relative; @include boxSizing(border-box); margin: 40px 0 15px;
    *{@include boxSizing(border-box);}
    &:before, &:after{content: ""; position: absolute; top: 23px; width: 1px; height: 9px; background: #cccccc;}
    &:before{left: 0;}
    &:after{right: 0;}
    .line-hgrey{position: absolute; top: 50%; left: 0; right: 0; @include transform(translateY(-50%)); height: 1px; background-color: #cccccc;}
    .notif-drag{
        position: absolute; top: -40px; left: 0; width: 140px; text-align: center; font-size: 12px; color: #ccc;
        .ic_drag_side{vertical-align: top;}
    }
    .list{
        float: left; width: 100px; height: 55px; margin: 0 18px; position: relative; text-align: center; cursor: pointer;
        &:before{content: "";position: absolute; top: 50%; left: 50%; width: 8px; height: 8px; background-color: #cccccc; @include borderRadius(100%); @include transform(translate(-50%, -50%)); @include transition(0.2s background);}
        .caps-circle{
            span{position: absolute; top: 0; left: 0; right: 0; font-size: 12px; color: #cccccc; font-weight: 500; @include transition(0.2s color);}
        }
        .caps{font-size: 16px; line-height: 40px; position: absolute; text-align: center;top: 0; left: 0; right: 0; bottom: 0; opacity: 0; font-weight: 700; height: 40px; margin: auto;  @include transition(0.2s opacity);}
        /*&.slick-current{
            &:before{opacity: 0;}
            .caps-circle{opacity: 0;}
            .caps{opacity: 1; @include borderRadius(20px); background: $green; color: $white;}
        }*/
        &:hover{
            &:before{background: $green;}
            .caps-circle{
                span{color: $green;}
            }
        }
    }
}

.box-drag-content{
    @include boxSizing(border-box);
    &:after{content: " "; clear: both; display: block;}
    .list{
        /*display: none;*/
        /*&.active{display: block;}*/
        .img-rekam{float: left; width: 32.14%;}
        .desc-rekam{
            float: right;width: 64.16%; padding-top: 45px; color: #666666;
            h3{text-transform: none; font-weight: 300; color: #333; margin-bottom: 20px;}
        }
    }
}

.box-visi{
    text-align: center; margin-top: 70px;
    .list{margin-bottom: 30px; min-height: 220px;}
    figure{margin-bottom: 32px;}
    h5{text-transform: none; color: #666666; font-weight: 600; }
}

.box-content-career{padding-right: 100px;}
.career-link-send{
    margin-left: 70px;
    h1{line-height: 50px;}
    h3{font-weight: 500; font-size: 20px; line-height: 22px;}
    h6{text-transform: none;}
    p{ color: #666666;}
    .line-yl{top: 50%; @include transform(translateY(-50%));}
}

.box-form{
    color: #999999;
    input[type="text"],
    input[type="email"],
    textarea,
    select{width: 100%; height: 40px; border: 1px solid #acacac; @include borderRadius(60px); padding: 0 27px; font-size: 18px; font-weight: 500; color: $blue;}
    textarea{height: 250px; padding-top: 20px; padding-bottom: 20px; resize: none; @include borderRadius(30px);}
    select{height: 40px; @include borderRadius(50px); padding-left: 30px; padding-right: 50px; background: url('../images/material/arr-select-box-pink.png') no-repeat right center;color: #999999; font-size: 16px; font-weight: 300;}
    select::-ms-expand {display: none;}
    @include placeholder{color: #999999; font-size: 16px; font-weight: 300;}
    span.notif{font-size: 13px; font-style: italic; margin-top: 10px; display: block;}
    .question{font-size: 24px; color: #666666; font-weight: 500;}
    .yciputra{font-size: 18px; font-weight: 700; color: $blue; margin-right: 30px;}
    .sready{font-size: 18px; color: #333333; font-weight: 500;}
    .row_list{
        .col{
            margin-bottom: 30px;
            &.no-marg{margin-bottom: 0; margin-top: 10px;}
            iframe{
                max-height: 80px !important;
            }
            &.date{
                label{
                font-size: 20px; margin-left: 10px; color: #000;
                }
            }
        }
        p{
            color: #000;
        }
    }
    .date_input{
        background: #fff url('../images/material/ico_date.png') no-repeat right;
    }
}
.input_file{
    position: relative; width: 100%; height: 60px; border: 1px solid #acacac; @include borderRadius(60px);
    input{width: 100%; height: 100%; opacity: 0; position: absolute; top: 0; left: 0; z-index: 3;}
    span.browse{position: absolute; top: 50%; left: 25px; right: 70px; @include transform(translateY(-50%)); max-height: 18px;color: #999999; font-size: 18px; font-weight: 300; z-index: 2; overflow: hidden;}
    .icwp{
        position: absolute; top: 50%; right: 30px; @include transform(translateY(-50%)); width: 23px; height: 25px; background-size: 100%;
    }
}

.opt {
    position: relative;display: inline-block;padding-left: 25px; line-height: 20px;cursor: pointer;
    .radio-custom {
        width: 18px; height: 18px; float: left; margin: 0px 5px 0 0; position: absolute !important;
        left: 0;cursor: pointer; background: url(../images/material/ic_radio.png) no-repeat bottom center;
        &.active {
            background-position: top center;
        }
    }
}

.box-maps{
    .maps{height: 350px;}
}

.box-filter{
    margin-bottom: 46px;
    label{font-weight: 700; font-size: 16px; line-height: 24px; margin-right: 32px; float: left; display: block; line-height: 50px;}
    input[type="text"],
    input[type="email"],
    select{height: 50px; border: 1px solid #cccccc; @include borderRadius(50px); padding: 0 50px 0 35px; float: left; @include boxSizing(border-box);
        font-size: 16px; font-weight: 700; margin-right: 28px;}
    select{background: url('../images/material/arr-select-box-pink.png') no-repeat right center;}
    select::-ms-expand {display: none;}
    .btn-submit{font-size: 16px; text-transform: uppercase; font-weight: 500; min-width: 120px;}
    .row_list{
        .col{margin-bottom: 0;}
    }
    &.box-filter-rekanan{
        select{width: 300px;}
    }
    &.box-filter-news{
        select{font-weight: 500; background: url('../images/material/arr-select-box-black.png') no-repeat right center;color: #333;}
        input[type="text"]{font-weight: 300;color: #333;}
    }
    &:after{content: " "; clear: both; display: block;}
}
.box-search-filter{
    position: relative; @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    &:after{content: " ";clear: both; display: block;}
    input[type=text]{padding-right: 65px; font-style: italic; border-color: #eaeaea;}
    input[type=submit]{position: absolute; top: 50%; right: 36px; @include transform(translateY(-50%)); width: 17px; height: 17px; background: transparent url('../images/material/ic_search.png') no-repeat center; border: none;}
}

.box-search-filter-result{
    width: 270px; margin-bottom: 47px;
    span{display: block; font-size: 14px; color: #333; margin-top: 10px;}
}

.box-form-career{
    width: 840px; margin: 0 auto 100px;
    &:after{content: " ";clear: both; display: block;}
}

.thankspage{
    position: relative; height: 100vh; min-height: 800px; 
    figure{position: absolute; top: 0; left: 0; right: 0; bottom: 0; @include boxSizing(border-box); padding-top: 110px; background: url('../images/content/thankspage.jpg') no-repeat center; background-size: cover; z-index: 1;}
    .bg-shadow-thankspage{z-index: 2; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: url('../images/material/shadow-thanks-page.png') no-repeat center; background-size: cover;}
    .desc{
        position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%)); text-align: center; z-index: 3; color: $white;
        .line-yl{width: 54px; height: 10px; margin-bottom: 20px;}
        h1{font-size: 50px; margin-bottom: 25px;}
        h4{text-transform: none; font-size: 25px; margin-bottom: 33px; font-weight: 500;}
    }
    &.inquiry{
        .bg-shadow-thankspage{z-index: 2; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: url('../images/content/ty.jpg') no-repeat center; background-size: cover;}
    }
}

.box-accordion{
    @include boxSizing(border-box); border: 1px solid #eaeaea;
    *{@include boxSizing(border-box);}
    h5{
        font-size: 22px; font-style: italic; color: #333333; font-weight: 400; cursor: pointer; margin-bottom: 0; @include transition(0.2s all);text-transform: none;
        &:hover{color: $pink;}
    }
    .line-yl{width: 15px; @include transition(0.2s all);}
    .list-accordion{
        border-bottom: 1px solid #eaeaea; padding: 45px 36px 41px 85px;
        &:last-child{border-bottom: none;};
        .sub-accordion{display: none; padding-top: 30px;}
        &.active{
            h5{color: $pink;}
            /*.sub-accordion{display: block;}*/
            .line-yl{width: 30px;}
        }
        &:hover{
            .line-yl{width: 30px;}
        }
    }
}
.box-maps{margin-bottom: 90px;}
.box-list-address{
    color: #666666;
    h5{font-weight: 500; font-size: 20px; color: #333333; margin-bottom: 10px; text-transform: none;}
    h6{font-size: 14px; color: #666666;}
    p{margin-bottom: 25px; min-height: 72px;}
    .inner-address{padding-left: 40px;}
    .line-yl{width: 20px; left: -35px;}
    .ad-telp{display: block; font-weight: 700; margin-bottom: 10px;}
    .col{margin-bottom: 100px;}
}

.slidenews{
    figure{
        img{vertical-align: top;}
    }
    .list{
        position: relative;
        &:before{content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: url('../images/material/bg-slide-news.png') no-repeat bottom center; background-size: cover;}
    }
    .desc{
        position: absolute; max-width: 100%; bottom: 70px; left: 0; right: 0; z-index: 3; color: $white;
        h5{color: $white; font-style: italic; font-weight: 400; margin-bottom: 20px;}
        h1{margin-bottom: 23px;}
    }
    .slick-prev, .slick-next{
        width: 25px; height: 60px; background-image: url('../images/material/arrow_slidenews.png'); background-repeat: no-repeat; background-size: 200% 100%; z-index: 10;
        &:before{display: none;}
    }
    .slick-prev{left: 7.69%; background-position: left center;}
    .slick-next{right: 7.69%; background-position: right center;}
}

.box-news-list{
    margin: 0 -15px; @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    .list{
        width: 33.33333333333%; padding: 0 15px; float: left; margin-bottom: 60px;
        figure{
            margin-bottom: 0; position: relative; overflow: hidden;
            img{vertical-align: top; @include transition(0.3s all);}
            .date{
                position: absolute; top: 0; left: 0; width: 70px; height: 70px; background: $green; color: $white; text-align: center; padding: 16px 10px 0; z-index: 2;
                h3{margin-bottom: 0;}
            }
        }
        h5{font-size: 20px; font-weight: 500; color: #333; text-transform: none; @include transition(0.3s color); overflow: hidden;}
        h6{font-size: 14px;}
        a{
            &:hover{
                h5{color: $blue;}
                .line-yl{width: 30px;}
            }
        }
        .line-yl{width: 20px; left: -45px; @include transition(0.3s width);}
        .desc{
            background: #fafafa; padding: 25px 44px 50px; border: 1px solid #e5e5e5; border-top: none; position: relative;
            .corner-link-bottom{position: absolute; bottom: 25px; left: 45px;}
        }
        .ic_vids{position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5) url('../images/material/ic_play_vids.png') no-repeat center; z-index: 10; cursor: pointer;}        

        &:hover{
            figure{
                img{@include transform(scale(1.1));}
            }
        }
    }
}
.box-news-vids{
    background: #fafafa; border: 1px solid #e5e5e5; @include fullfloat; @include boxSizing(border-box); color: #666666;
    *{@include boxSizing(border-box);}
    &:after{content: " ";clear: both; display: block;}
    figure{margin: 0; position: relative;}
    h4{color: #333; line-height: 28px; margin-bottom: 15px;}
    h6{font-size: 14px;}
    .left{width: 59.82%;}
    .right{width: 31.53%; padding: 36px 69px 0 0;}
    .ic_vids{position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5) url('../images/material/ic_play_vids.png') no-repeat center; z-index: 10; cursor: pointer;}
}

.paging{
    text-align: center; margin: 130px 0 50px; @include fullinline;
    &:after{content: " ";clear: both; display: block;}
    a{
        font-size: 18px; font-weight: 700; line-height: 24px; color: #999999; display: inline-block; margin: 0 15px; @include transition(0.2s all); vertical-align: middle;
        &:hover, &.active{color: $pink;}
        &.prev, &.next{width: 9px; height: 16px; background-image: url('../images/material/arr-paging.png'); background-repeat: no-repeat; position: relative; top: -1px;}
        &.prev{
            background-position: top left;
            &:hover{background-position: bottom left;}
        }
        &.next{
            background-position: top right;
            &:hover{background-position: bottom right;}
        }
    }
}

.box-detail-news{
    &:after{content: " "; clear: both; display: block;}
    .left-news{float: left; width: 71.28%;}
    .right-news{float: right; width: 22.99%;}
    .box-title{
        padding-left: 42px; margin-top: 26px; margin-bottom: 68px;
        h6{margin-bottom: 0; font-size: 18px;}
        .line-vly{left: 0; bottom: 10px;}
        &.minmargbot{margin-bottom: 30px;}

    }
    .box-list-news-right{margin-top: 20px;}
}
.box-list-news-right{
    .list-news{
        @include fullinline; margin-bottom: 20px;
        .left{width: 70px;}
        .right{width: 188px;}
        figure{margin: 0;}
        h6{text-transform: none; font-size: 14px; line-height: 18px; color: #333; margin-bottom: 0; font-weight: 500; @include transition(0.2s color)}
        .datenews{font-size: 12px; line-height: 18px; color: #979696; font-weight: 300;}
        a:hover{
            h6{color: $yellow;}
        }
    }
}

.box-link-archives{
    a{
        display: block; border-bottom: 1px solid #e6e6e6; font-size: 14px; color: #333333; @include transition(0.2s color); padding: 13px 0 8px; line-height: 14px;
        &:hover{color: $yellow;}
    }
}

.slidetitle{
    position: relative; @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    input{display: none;}
    .box-slidetitle{
        position: relative; border: 1px solid #acacac; @include borderRadius(60px); float: left; overflow: hidden; height: 40px;
        .list-slidetitle{
            position: relative; text-align: center; float: left; padding: 0 30px; height: 40px; line-height: 40px; @include borderRadius(60px); font-size: 18px; font-weight: 300; cursor: pointer;
            &:before{content: ""; position: absolute; top: 0; left: 100%; width: 100%; height: 100%; @include borderRadius(60px); @include transition(0.4s all); background: $blue; z-index: -1;}
            &:hover{
                color: $blue;
            }
            &.active{
                color: $white; font-weight: 500;
                &:before{left: 0;}
                &:hover{
                    color: $white;
                }
            }
            &:last-child{
                &:before{left: auto; right: 100%;}
                &:hover{
                    color: $blue;
                }
                &.active{
                    &:before{right: 0; left: auto;}
                    &:hover{
                        color: $white;
                    }
                }
            }
        }
    }
}

.box-sitemap{
    padding-left: 42px; font-size: 16px; margin-bottom: 100px;
    a{
        color: #333; @include transition(0.2s color);
        &:hover{color: $yellow;}
    }
    h5{font-size: 20px; text-transform: uppercase; display: block;margin-bottom: 15px;}
    ul{
        padding-left: 55px;
        li{
            position: relative; margin-bottom: 15px;
            &:before{content: ""; position: absolute; top: 3px; left: -17px; width: 8px; height: 8px; background: $yellow;}
        }
    }
    .list-sitemap{margin-bottom: 30px;}
}

.box-result-search{
    margin-bottom: 100px;
    .list-result-search{
        position: relative; padding-left: 17px; color: #666666;
        &:before{content: ""; position: absolute; top: 6px; left: 0; width: 8px; height: 8px; background: $yellow;}
        h6{text-transform: none;}
    }
}

.box-outer-drag-nav-slide{
    position: absolute; top: 0; left: 0; right: 0; height: 100%; overflow: hidden;
}
.box-drag-nav-slide.ui-slider-horizontal{
    position: absolute; top: 0; left: 65px; right: 65px; height: 100%; background: transparent; border: none; @include transition(0.4s all);
    .ui-slider-handle, .ui-slider-range{@include transition(0.5s all);}
    .ui-slider-handle{width: 104px; margin-left: -52px; height: 40px; @include boxSizing(border-box); top: 7px; @include borderRadius(30px); background: $green; border: none; text-align: center; vertical-align: middle; line-height: 40px; color: $white; font-weight: 700;}
    .ui-slider-range{background: transparent;}
}
.box-qst-cpt{
    .left{width: 48%;}
    .right{width: 52%; text-align: right;}
}

.box-manfaat{
    margin: 0 -15px; display: block;
    &:after{content: " ";clear: both; display: block;}
    figure{margin-bottom: 0;}
    .list-manfaat{
        width: 33.33333333333333%; float: left; padding: 0 15px; @include boxSizing(border-box); margin-bottom: 20px; overflow: hidden;
        .left{width: 26.31%;}
        .right{width: 71%;}
        &:nth-child(3n + 1){
            clear: both;
        }
    }
}

.box-link-contact{
    a{
        font-weight: 700; color: #333;
        i{margin-right: 5px;}
    }
}
.margtophead{margin-top: 108px;@include fullinline;}
.blog-box{
    @include afterboth;
    .lg{float: left;width: 63%;}
    .thumb{
        float: right;width: 36.8%;
        .list{
            width: 49%;float: left;margin-right: .5%;position: relative;margin-bottom: 1px;cursor: pointer;
            &:before{content:"";position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;background: #007858;opacity: 0;@include transition(.2s all ease-out);}
            figure{
                position: relative;
                &:before{content:"";position: absolute;top: 0;left: 0;right: 0;bottom: 0;background: url('../images/material/bg-blog-sm.png')repeat-x center bottom;z-index: 1;}
                img{width: 100%;}
            }
            .desc{
                position: absolute;bottom: 0;left: 0;right: 0;z-index: 3;color: #fff;padding: 0 20px 20px;
                h5{font-size: 14px;line-height: 18px;text-transform: none;max-height: 36px;overflow: hidden;}
            }

            &:nth-child(even){margin-right: 0;}
            &.active,&:hover{
                &:before{opacity:.5;}
            }
        }
    }
}
.sliderblog-lg{
    .list{
        position: relative;display: block;
        figure{
            position: relative;
            &:before{content:"";position: absolute;top: 0;left: 0;right: 0;bottom: 0;background: url('../images/material/bg-blog-lg.png')repeat-x center bottom;z-index: 1;}
            img{width: 100%;}
        }
        .desc{
            position: absolute;bottom: 0;left: 0;right: 0;z-index: 2;padding: 0 70px 50px;color: #fff;
            span{display: block;font-size: 18px;line-height: 26px;font-style: italic;margin-bottom: 10px;}
            h2{font-size: 40px;line-height: 44px;text-transform: none;max-height: 88px;overflow: hidden;}
        }
    }
}

.link-list{
    margin-bottom: 40px;
    a{display: block;font-size: 16px;line-height: 24px;color: $green;margin-bottom: 5px;}
}




/* footer
----------------------------------------------------------------------------------------------*/
footer{
    .foot-top{
        background: #131619; color: $white; padding: 53px 0 60px;  font-size: 16px; line-height: 24px; color: #999999;
        a{color: $white;}
        .prof-foot{
            float: left; width: 24.06%;
            .logo-foot{margin-bottom: 30px;}
            .address{
                a{
                    font-weight: 700;color: #999999;
                    &:hover{color: $yellow;}
                }
            }
        }
        .box-link-foot{
            float: right; width: 75.93%; padding: 42px 0 0;
            h5{position: relative; text-transform: uppercase;margin-bottom: 15px; color: $white;
                &:before{content: ""; @extend .line-yl; width: 25px; position: absolute; top: 3px; left: -44px;}
            }
            .box-link-foot-left{
                width: 75.47%; float: left;
                .list-link-foot{
                    float: left; padding-left: 52px; width: 33.3333333%; @include boxSizing(border-box); margin-bottom: 28px;
                    a{
                        color: #999;
                        &:hover{color: $white;}
                    }
                    &:nth-child(3n + 1){
                        clear: both;
                    }
                }
            }
            .box-link-sosmed{
                width: 24.52%; float: right;
                a{
                    color: #999;
                    &:hover{
                        color: $white;
                        .icwp{background-position: bottom center;}
                    }
                }
                .icwp{vertical-align: middle; margin-right: 5px;}
            }
        }
    }
    .foot-bottom{
        padding: 22px 0; font-size: 16px; color: #666666;
        .left{padding-top: 26px;}
        .right{
            span{float: left; padding-top: 26px; margin-right: 16px;}
            figure{float: right;}
        }
    }
    .foot-line{@include fullfloat; height: 5px; @extend .bggradient;}
}

/* popup
----------------------------------------------------------------------------------------------*/

.wrap_popup {
    position: fixed; height: 100%;top: 0; right: 0; bottom: 0; left: 0;
    width: 100%; background: #fff; z-index: 200; display: none; overflow-y: auto;
    .pop_inner {
        position: absolute;display: table;height: 100%;width: 100%;
        .overlay_pop {
            position: absolute;left: 0;right: 0;top: 0;bottom: 0; margin: auto; z-index: 7;
        }
        .close{
            width: 56px ; height: 56px; position: absolute; right: 45px; top: 45px; display: block; 
            background: url("../images/material/close_pop.png") no-repeat bottom center; z-index: 8;
            &:hover{
                background-position:top center !important; 
            }
        }
        .box_pop {
            display: table-cell;vertical-align: middle;
        }
        .content_pop  {  
            width: 830px; margin: 5% auto;background: #fff;position: relative;z-index: 8; padding: 0; @include borderRadius(10px);
            article{
                width: auto;
            }    
        }
    }
}


/* custom datepicker
----------------------------------------------------------------------------------------------*/
.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0;
    background: #ffc107;
    margin: 0 0;
}
ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: none;
    background: #f6f6f6;
    font-weight: normal;
    color: #454545;
    padding: 5px;
}

.ui-widget.ui-widget-content {
    border: none;
    z-index: 9999 !important;
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    -webkit-box-shadow: 5px 3px 16px -2px #ccc;
}
.ui-datepicker th {
    padding: .7em .3em;
    text-align: center;
    font-weight: bold;
    border: 0;
    border-bottom: 1px solid #e6e6e6;
    color: #999999;
}
.ui-datepicker table {
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0 0 .4em;
    
}
.ui-datepicker table td{
    &.ui-datepicker-today {
        a{
            color:#fff;background:#ff0335;
        }
    }
    a{
        text-align: center; @include borderRadius(30px)
        &:hover{ color:#fff;background:#ff0335; }
    }
}
.ui-datepicker .ui-datepicker-next {
    right: 12px;
    background: url(../images/material/arr_right.png) no-repeat center;
}

.ui-datepicker .ui-datepicker-prev {
    left: 1 2px;
    background: url(../images/material/arr_left.png) no-repeat center;
}

.testiHome {
    padding: 35px 0; position: relative;
    .wrapper {width: 1430px;}
}
.trigger-anim-testi {position: absolute; top: 60%;}
.ls-Testi {
    .slide {
        padding: 100px 0px 40px; @include transform(scale(0.9)); opacity: 0.6; @include transition(all ease-out 0.6s);
        &.slick-center {@include transform(scale(1)); opacity: 1; @include transition(all ease-out 0.6s);}
    }
    .slick-list {padding: 0 !important;}
    .ic {width: 120px; height: 120px; @include borderRadius(50%); display: block; margin: -80px auto 20px; overflow: hidden; position: relative; z-index: 2;}
    .box_testi {
        text-align: center; margin-top: -95px; background: #ebebeb; padding: 90px 40px 40px; font-family: Open Sans, sans-serif; position: relative; z-index: 1;
        h3 {font-size: 23px; font-weight: 700; color:#047a5a; text-transform: none; margin-bottom: 20px;}
        h4 {color: #333; font-size: 19px; font-weight: 400; margin-bottom: 20px;}
        p {font-size: 16px; font-weight: 300; height: 120px; overflow: hidden;}
        &:before {
            content: ""; left: 20px; right: 20px; bottom: 20px; top: 20px; border: 2px solid #ccc; position: absolute;
        }
        .bg {width: 70px; height: 52px; background: url('../images/material/bg_testi.png'); position: absolute; left: 55px; top: 145px;}
        
    }
    .slick-dots {
        li {
            button {
                width: 20px; height: 20px; border: 2px solid #047a5a; @include borderRadius(50%); @include transform(scale(0.72)); background: #fff; @include transition(all ease-out 0.3s);
                &:before {display: none;}
            }
            &.slick-active {
                button {@include transform(scale(1)); background: #ffc107; @include transition(all ease-out 0.3s);}
            }
        }
    }
    &.tri {
        .slide {
            width: 33.333%; float: left;
            &:nth-child(2) {@include transform(scale(1)); opacity: 1; @include transition(all ease-out 0.6s);}
        }
    }
}


.middle-nobanner {
    padding-top: 160px;
}
.section-telemarketing {
    padding: 100px 0; position: relative;
    .center {text-align: center;}
    &:first-child {padding-top: 0;}
    &.grey {background: #F7F7F7;}
    &.gradient {
        background: rgb(2,55,112) url('../images/material/bg-gradient.png') no-repeat right center;
        color: #fff;
        .title-section {color: #fff;}
        p {color: #fff;}
    }
    p {font-size: 18px; line-height: 26px; color: #666666; margin: 0 0 20px;}
    .title-section {
        font-size: 23px; font-weight: 700; color: #108062; text-align: center;line-height: 34px; margin-bottom: 42px;
        &.no-center {text-align: left; }
    }
    .list-box {
        .box-item {
            display: flex; flex-wrap: nowrap; align-items: center; justify-content: space-between; margin: 0 0 30px;
            &:nth-child(2n) {
                figure {order: 2;-webkit-order:2;}
                figcaption {
                    order: 1;-webkit-order:1; padding-left: 0; padding-right: 60px;
                    &:before {left: auto; right: 0;}
                }
            }
        }
        &.first-right {
            .box-item {
                figure {order: 2;-webkit-order:2;}
                figcaption {
                    order: 1;-webkit-order:1; padding-left: 0; padding-right: 60px;
                    &:before {left: auto; right: 0;}
                }
                &:nth-child(2n) {
                    figure {order: 1;-webkit-order:1;}
                    figcaption {
                        order: 2;-webkit-order:2; padding-left: 60px; padding-right: 0;
                        &:before {left: 0; right: auto;}
                    }
                }
            }
        }
        figure {
            width: 465px; position: relative;
        }
        figcaption {
            position: relative; width: calc(100% - 526px); padding-left: 60px; box-sizing: border-box;
            &:before {content: ''; height: 6px; width: 30px; background: #FFC107; display: block; position: absolute; left: 0; top: 30px;}
        }
    }
    .list-icon {
        .item {
            display: flex; align-items: center;margin: 0 0 40px;
            .ico {
                width: 52px;
                img {display: block;}
            }
            .keterangan {width: calc(100% - 52px); padding-left: 22px; box-sizing: border-box;font-size: 16px; line-height: 24px; font-weight: 500;}
        }
        &.solusi {
            display: flex; flex-flow: wrap; margin: 0 -30px;
            .item {width: 50%; padding: 0 30px; box-sizing: border-box; }
        }
    }
    .box-yellow {
        background: #FFC107; text-align: center; padding: 15px 30px; margin: 50px 0;
        p {font-size: 16px; line-height: 24px; margin: 0; color: #2B2B2B;}
    }
    .form-cek {
        display: flex; justify-content: space-between;
        .cek-number {
            width: calc(100% - 465px);
            h6 {font-size: 16px; font-weight: 700; margin: 0 0 12px; color: #434343;}
            form {
                position: relative; border-radius: 30px;width: 460px;
                input[type="text"],
                input[type="number"] {
                     box-sizing: border-box; width: 100%; border-radius: 30px; padding: 0 100px 0 85px; height: 60px;
                     font-size: 16px;color: #434343;
                }
                &::placeholder {opacity: 0.5;}
                span {
                    position: absolute; left: 35px; font-size: 16px; display: inline-block; line-height: 26px;color: #434343;
                    top: 50%; transform: translateY(-50%); padding-right: 12px; border-right: 1px solid #707070;
                }
                button {
                    position: absolute; right: 0; background: #FF0335; color: #fff; top: 0; bottom: 0; padding: 0 35px;
                    font-size: 16px; border: none; border-radius: 30px; cursor: pointer; font-weight: 600; text-transform: uppercase;
                }
            }
        }
        > p {width: 465px; margin: 0;}
    }
}
.wrap-notif {
    background: transparent;
    .pop_inner {
        .overlay_pop {background: rgba(0,0,0,0.8);}
        .content_pop {
            width: 570px; padding: 50px 50px 60px; box-sizing: border-box; text-align: center; border-radius: 0;
            .ico {display: block; margin: 0 0 30px;}
            p {font-size: 20px; font-weight: 500;margin: 0 0 30px;}
            .close-notif {
                line-height: 50px; display: inline-block; min-width: 160px; color: #fff;
                border-radius: 25px; background: #8DC640; font-weight: 700; text-transform: uppercase;
            }
        }
    }
    
}
#notif-success {
    .pop_inner {
        .content_pop {border-top: 8px solid #8DC640;}
    }
}
#notif-cancel {
    .pop_inner {
        .content_pop {
            border-top: 8px solid #FF365E;
            .close-notif {
                 background: #FF365E;
            }
        }
    }
}